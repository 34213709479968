const getUrl = (elem) => {
	if (!elem.dataset.videoUrl) { return getUrl(elem.parentNode); }
	if (elem.dataset.videoUrl) { return elem.dataset.videoUrl; }
}

const watchVideoIfYouPlease = (evt) => {
	const scrollY = window.scrollY;
	document.querySelector('body').style.top = `-${scrollY}px`;
	document.querySelector('html').style.scrollBehavior = 'unset';
	const modal = document.querySelector('#video-modal');
	modal.classList.add('open');

	console.log(evt.target);
	let url = getUrl(evt.target);
	// const content = `<iframe src="${url}" frameborder="0" allowfullscreen></iframe>`;
	modal.querySelector('.embed-container iframe').src = url;

	modal.querySelector('.close').addEventListener('click', () => {
		modal.classList.remove('open');
		modal.querySelector('.embed-container iframe').src = '';
		document.querySelector('body').style.position = '';
		document.querySelector('body').style.top = '';
		// window.scrollTo(parseInt(scrollY) * -1);
		document.querySelector('html').style.scrollBehavior = 'smooth';
	});
};

window.addEventListener('load', event => {
	/**
	 * Stories Glider
	 */
	
	$('#stories').slick({
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		centerMode: false,
		nextArrow: document.querySelector('#arrows-top .next'),
		prevArrow: document.querySelector('#arrows-top .prev'),
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					nextArrow: document.querySelector('#arrows-bottom .next'),
					prevArrow: document.querySelector('#arrows-bottom .prev'),
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			}
		]
	});

	/**
	 * Timeline Glider
	 */
	const timelineEntries = document.querySelectorAll('.entry');
	if (timelineEntries) {
		timelineEntries.forEach(entry => {
			const img = entry.querySelector('img');
			entry.style.width = `${img.width}px`;
		});
	}

	$('#timeline').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		centerMode: false,
		variableWidth: true,
		nextArrow: document.querySelector('#timeline-arrows-top .timeline-next'),
		prevArrow: document.querySelector('#timeline-arrows-top .timeline-prev'),
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					variableWidth: false,
					variableHeight: true,
					nextArrow: document.querySelector('#timeline-arrows-bottom .timeline-next'),
					prevArrow: document.querySelector('#timeline-arrows-bottom .timeline-prev'),
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					variableWidth: false,
					variableHeight: true,
				}
			}
		]
	});

	/**
	 * Modal for video
	 */
	const playBtns = document.querySelectorAll('.still');
	if (playBtns.length > 0) {
		playBtns.forEach(btn => {
			btn.addEventListener('click', watchVideoIfYouPlease);
		});
	}
});